import React from 'react';

const Header = () => {
    return (
        <header data-id="header" className="header-wrapper custom-header-bg-color">
            <div className="header-title-wrapper custom-header-font-color">
                <span data-id="header-title" className="header-title">
                    Appointment confirmation
                </span>
            </div>
        </header>
    );
};

export default Header;