import React from "react";
import { DatePicker } from "antd";
import moment from 'moment'

import "./style.css";

const DatePick = ({ className, placeholder, onChange, disabledDate, defaultValue }) => {
  const customFormat = value => moment().format("ll") === value.format("dddd Do") ? "TODAY" : value.format("dddd Do");
  return (
    <DatePicker
      className={className}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      disabledDate={disabledDate}
      defaultValue={defaultValue}
      format={customFormat}
    />
  );
};

export default DatePick;
