import React from 'react';

const PhoneIcon = () => {
  return (
    <svg viewBox="0 0 23.23 23.23" className="header__business__icon">
      <title>Asset 4</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <path
            d="M18.33,23.23A18.24,18.24,0,0,1,5.7,17.61l-.08-.08h0A18.16,18.16,0,0,1,0,4.57V4.23L.22,4a13.14,13.14,0,0,1,1-1.09A12.08,12.08,0,0,1,5.43.11,1.88,1.88,0,0,1,6.08,0c.54,0,1.75.27,2.06,1.12.54,1.52,1.23,3.5,1.74,5.39a2.41,2.41,0,0,1-.74,2.28L7.37,9.91a1.28,1.28,0,0,0,.24.61A23.85,23.85,0,0,0,10,13.28a24.48,24.48,0,0,0,2.74,2.34,1,1,0,0,0,.56.21l.61-.85.56-.89a2.39,2.39,0,0,1,1.86-.78,1.48,1.48,0,0,1,.38,0,25.58,25.58,0,0,1,5.5,1.77,2.37,2.37,0,0,1,.93,2.69A12.08,12.08,0,0,1,20.36,22a14.39,14.39,0,0,1-1.1,1l-.27.21ZM2,5A16.46,16.46,0,0,0,7,16.12l.1.1a16.21,16.21,0,0,0,11.14,5c.23-.19.46-.4.67-.6a10.21,10.21,0,0,0,2.3-3.55s-.05-.13-.09-.22a23.68,23.68,0,0,0-4.91-1.56l-.24,0-1,1.58a2.46,2.46,0,0,1-3.48.31,28.53,28.53,0,0,1-3-2.54A27.77,27.77,0,0,1,6,11.7a2.84,2.84,0,0,1-.55-2.19,2,2,0,0,1,.87-1.29l1.58-1a1.6,1.6,0,0,0,0-.27C7.49,5.3,6.86,3.5,6.35,2.06A.94.94,0,0,0,6.08,2,10.37,10.37,0,0,0,2.6,4.29q-.3.3-.6.66Z"
            fill="#4f453c"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default PhoneIcon;
