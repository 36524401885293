import React from "react";
import { Input } from "antd";
import PropTypes from "prop-types";
import classNames from "classnames";


import './style.css';

const TextInputComponent = (props) => {
  const {
    value,
    name,
    disabled,
    maxValueLength,
    onClick,
    className,
    placeholder,
    type,
    inputProps,
    onChange,
    onBlur,
    ...otherProps
  } = props;

  return (
    <div className={classNames("custom-input", className)}>
      <Input
        value={value}
        name={name}
        placeholder={placeholder}
        onClick={onClick}
        maxLength={maxValueLength}
        disabled={disabled}
        type={type}
        onBlur={onBlur}
        {...otherProps}
        {...inputProps}
        onChange={onChange}
        keyboardType={type === "number" ? "numeric" : "default"}
      />
    </div>
  );
};

TextInputComponent.defaultProps = {
  name: "",
  disabled: false,
  onClick: (event) => event.stopPropagation(),
  className: "",
  placeholder: "",
  value: undefined,
  maxValueLength: null,
  type: "text",
  inputProps: {},
  autoFocus: false,
  onChange: () => {
    /* This is intentional */
  },
};

TextInputComponent.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValueLength: PropTypes.number,
  type: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default TextInputComponent;
