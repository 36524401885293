import React from "react";
import Salon from "../../components/Salon";

const FeedbackThankyou = () => {
  return (
    <>
      <Salon onSalonDataReceived={() => {}} />
      <main class="main appointment">
        <div class="container">
          <div name="service-container">
            <div class="appointment__form">
              <div class="appointment__form__services">
                <div class="review-note-container">
                  <p class="review-note">
                    You have already rated your experience you within this
                    transaction. We are hoping to see you again soon! <br />
                    <span>Thank You!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default FeedbackThankyou;
