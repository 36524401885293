import moment from "moment";
import React from "react";
import DatePicker from "../../../../components/DatePicker";

const AppointmentDate = ({
  resetData,
  setAppointmentDate,
  getServices,
  salon,
  appointmentDate
}) => {
  const handleChange = (value) => {
    resetData();
    if (value) {
      setAppointmentDate(value.format("YYYY-MM-DD"));
      getServices({}, `/${salon?._id}`);
    }
  };

  const disableDate = (current) => {
    let customDate = moment();
    if (salon && salon.startBooking === "1 day") {
      customDate.add(1, "days");
    }
    customDate = customDate.format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  return (
    <div className="appointment__form__date text--center">
      <DatePicker
        className="date-picker"
        placeholder="Select Date"
        onChange={handleChange}
        defaultValue={moment(appointmentDate)}
        disabledDate={(current) => {
          disableDate(current);
        }}
      />
    </div>
  );
};

export default AppointmentDate;
