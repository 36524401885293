import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Booking from "../screens/Booking";
import Feedback from "../screens/Feedback";
import ConfirmBooking from "../screens/ConfirmBooking";
import './app.css'
import "antd/dist/antd.css";
import SocialFeedback from "../screens/SocialFeedback";
import Thankyou from "../screens/Thankyou";

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        {/* <PageBackground /> */}
        <Switch>
          <Route
            exact
            path='/confirm-booking'
            component={ConfirmBooking}
            key="main page"
          />
          <Route
            exact
            path='/:salonId'
            component={Booking}
            key="main page"
          />
          <Route
            exact
            path='/:salonId/review/:clientId'
            component={Feedback}
            key="main page"
          />
          <Route
            exact
            path='/:salonId/review/:clientId/social'
            component={SocialFeedback}
            key="main page"
          />
          <Route
            exact
            path='/:salonId/review/:clientId/thankyou'
            component={Thankyou}
            key="main page"
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
