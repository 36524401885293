import React from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";
import "./style.css";
import classNames from "classnames";

function MultiSelectBox(props) {
  const {
    options,
    value,
    onChange,
    loading,
    className,
    error,
    notFoundContent,
  } = props;
  const isActive = (item) => {
    const selectedOptions = (value || []).filter((option) => {
      return option.value === item.value;
    });
    return selectedOptions.length;
  };
  return (
    <div
      className={classNames(
        "custom-select-box",
        className,
        error ? "error" : ""
      )}
    >
      {loading ? (
        <div className="flex__col--12 text--center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="appointmentSlots">
          <div className="appointmentSlotsContainer">
            {options && options.length ? (
              options.map((item) => {
                return (
                  <div
                    className={classNames(
                      "appointmentSlot",
                      isActive(item) && "active"
                    )}
                    key={item.value}
                    onClick={() => onChange(item)}
                  >
                    {item.name}
                  </div>
                );
              })
            ) : (
              <div className="flex__col--12 text--center appointmentSlot">
                {notFoundContent}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

MultiSelectBox.defaultProps = {
  options: [],
  name: "",
  disabled: false,
  placeholder: "Select an option",
  allowClear: true,
  value: [],
  defaultValue: undefined,
  selectedOption: null,
  onChange: () => {
    /* This is intentional */
  },
  loading: false,
  showSearch: false,
  filterOption: undefined,
  dropdownMatchSelectWidth: false,
  error: false,
  notFoundContent: "No results found",
  isWorkerSelectBox: false,
  isLoading: false,
};

MultiSelectBox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isWorkerSelectBox: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.instanceOf(Array),
  value: PropTypes.array,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  notFoundContent: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default React.memo(MultiSelectBox);
