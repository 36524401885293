export const baseUrl = 'https://server.salondragon.com';

export const apiUrls = {
    SERVICES: "/service",
    SALON_DETAIL: "/salon",
    WORKERS: "/booking/workers",
    AVAILABLE_SLOT: "/booking/slots",
    BOOK_APPOINTMENTS: "/booking",
    TEMP_BOOK_APPOINTMENTS: "/booking/temp",
    RESET_BOOK_APPOINTMENTS: "/booking/reset",
    CONFIRM_BOOKING: "/booking/action",
    GET_BOOKING_DETAIL: "booking/detail"
};
