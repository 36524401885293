import React, { useEffect, useState } from "react";
import { notification } from "antd";
import moment from "moment";
import classNames from "classnames";
import { apiUrls } from "../../api/constants";
import Button from "../../components/Button";
import Salon from "../../components/Salon";
import { useGet, usePost } from "../../hooks/use-http";
import useRedirect from "../../hooks/useRedirect";

import "./style.css";
import TextInputComponent from "../../components/TextInput";
import Summary from "./components/Summary";
import { Loader } from "../../components/Loader";
import Service from "./components/Services";
import AppointmentDate from "./components/AppointmentDate";
import Worker from "./components/Worker";
import TimeSlot from "./components/TimeSlot";
import { numberToOrdinal } from "../../lib/util";
import { filterTimes, parseAvailableSlots, sortTime } from "./helpers";
import RemainingTimer from "../../components/RemainingTimer";

const getTempApptTime = (appointments, index) => {
  let slotsObj = [];
  for (let i = 0; i < appointments.length; i++) {
    const services = appointments[i].service || [];
    for (let j = 0; j < services.length; j++) {
      if (!services[j].worker) {
        continue;
      }
      const obj = {
        timing: {
          from: services[j].startTime,
          to: services[j].endTime,
        },
        staffId: services[j].worker.value,
      };
      slotsObj.push(obj);
    }
  }
  return slotsObj;
};

const Booking = () => {
  const { params, query } = useRedirect();
  const [salon, setSalon] = useState();
  const [loading, setLoading] = useState(true);
  const [appointmentDate, setAppointmentDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [showCustomNotification, setShowCustomNotification] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointments, setAppointments] = useState([{}]);
  const [isContinueBooking, setIsContinueBooking] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});
  const [submitDisable, setSubmitDisable] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const {
    execute: resetBookingApts,
  } = usePost(apiUrls.RESET_BOOK_APPOINTMENTS);

  const handleResetTimer = () => {
    setResetTimer(true);
    setTimeout(() => {
      setResetTimer(false); // Reset it after a short delay to allow the timer to reset
    }, 100);
  }

  // useEffect(() => {
  //   if (resetApptResponse) {
  //     appointments[currentIndex].data = [];
  //     setAppointments([...appointments]);
  //   }
  // }, [resetApptResponse])

  const resetData = () => {
    setCurrentIndex(0);
    setAppointments([{}]);
    setIsContinueBooking(false);
    setCustomerInfo({});
    setSubmitDisable(false);
  };

  const {
    data: servicesResponse,
    servicesError,
    execute: getServices,
    loading: servicesLoading,
  } = useGet(apiUrls.SERVICES, {}, { auto: false });

  useEffect(() => {
    if (servicesError) {
      notification.error({
        message: "Error",
        description: "Error while fetching services",
      });
    }
  }, [servicesError]);

  const {
    data: workerResponse,
    workerError,
    execute: getWorkers,
    loading: workerLoading,
  } = useGet(apiUrls.WORKERS, {}, { auto: false });

  useEffect(() => {
    if (workerError) {
      notification.error({
        message: "Error",
        description: "Error while fetching workers",
      });
    }
  }, [workerError]);

  const {
    data: availableSlots,
    availableSlotsError,
    execute: getAvailableSlot,
    loading: availableSlotsLoading,
  } = useGet(apiUrls.AVAILABLE_SLOT, {}, { auto: false });

  useEffect(() => {
    if (availableSlotsError) {
      notification.error({
        message: "Error",
        description: "Error while fetching slots",
      });
    }
  }, [availableSlotsError]);

  const {
    data: bookAppointmentResponse,
    bookAppointmentError,
    execute: bookAppointment,
    loading: bookAppointmentLoading,
  } = usePost(apiUrls.BOOK_APPOINTMENTS);

  useEffect(() => {
    if (bookAppointmentError) {
      notification.error({
        message: "Error",
        description: "Error while making appts",
      });
      setSubmitDisable(false);
    }
  }, [bookAppointmentError]);

  useEffect(() => {
    if (bookAppointmentResponse) {
      if (bookAppointmentResponse.bookingUrl) {
        window.open(bookAppointmentResponse.bookingUrl, "_self");  
        return;
      } 
      setShowCustomNotification(true);
      setStartTimer(false);
      setTimeout(() => {
        setShowCustomNotification(false);
        if (query.bookingId) {
          window.open(`/${salon.bookingId}`, "_self");
        }
      }, 10000);
      resetData();
      getServices({}, `/${salon._id}`);
    }
  }, [bookAppointmentResponse]);

  const onSalonDataReceived = (data) => {
    if (data) {
      setSalon(data);
      setLoading(false);
      getServices({}, `/${data._id}`);
      if (data.startBooking === "1 day") {
        setAppointmentDate(moment().add(1, "days").format("YYYY-MM-DD"));
      }
    }
  };

  useEffect(() => {
    if (servicesResponse) {
      appointments[currentIndex].services = (servicesResponse || []).map(
        (item) => {
          let orderValue = -1;
          if (item.toggleOption === "hands") {
            orderValue = 2;
          } else if (item.toggleOption === "special") {
            orderValue = 1;
          } else if (item.toggleOption === "feet") {
            orderValue = 0;
          }
          return {
            name: item.name,
            value: item._id,
            time: item.time,
            amount: item.defaultAmount,
            acrylic: item.acrylic,
            dip: item.dip,
            wax: item.wax,
            design: item.design,
            toggleOption: item.toggleOption,
            servicePolyGel: item.servicePolyGel,
            serviceGel_x: item.serviceGel_x,
            serviceHardGel: item.serviceHardGel,
            orderValue,
          };
        }
      );
      setAppointments([...appointments]);
      if (!appointmentDate) {
        alert("Please select date");
        return;
      }
    }
  }, [servicesResponse]);

  const getSlots = () => {
    let obj = {};
    if (currentIndex > 0) {
      obj = getTempApptTime(appointments, currentIndex);
    }

    const worker = appointments[currentIndex]?.worker;
    const services = appointments[currentIndex]?.service?.map((item) => {
      return {
        serviceId: item.value,
        time: item.time,
      };
    });
    const workerIds = appointments[currentIndex]?.workersResponse
      ?.filter((item) => {
        if (!worker || worker.value === "any" || worker.value === item.value) {
          return item;
        }
      })
      .map((item) => item.value);
    
    getAvailableSlot({
      params: {
        salonId: salon._id,
        organizationId: salon.organization,
        date: appointmentDate,
        workerIds,
        services,
        request: appointments[currentIndex]?.request,
      },
    });
  };

  useEffect(() => {
    if (workerResponse) {
      appointments[currentIndex].workersResponse = (workerResponse || []).map(
        (item) => ({
          name: item.userId,
          value: item._id,
        })
      );
      appointments[currentIndex].workers = [
        { name: "Any Technician", value: "any" },
        { name: "Request Technician", value: "request" },
      ];
      setAppointments([...appointments]);
      getSlots();
    }
  }, [workerResponse]);

  useEffect(() => {
    if (availableSlots) {
      let slots = parseAvailableSlots(availableSlots);
      let slotTimeForDisplay = Object.keys(slots || {}).sort(sortTime);
      slotTimeForDisplay = filterTimes(slotTimeForDisplay);
      appointments[currentIndex].totalSlots = slots;
      appointments[currentIndex].slots = slotTimeForDisplay.map((slot) => ({
        value: slot,
        name: moment(slot, "HH:mm").format("hh:mm A"),
      }));
    }
  }, [availableSlots]);

  useEffect(() => {
    const data = appointments.filter((item) => {
      return item?.data?.length;
    });
    if (!data.length) {
      setStartTimer(false);
    }
  }, [appointments])

  const validateAppointments = () => {
    let isValid = true;
    appointments?.forEach((appointment) => {
      if (!appointment.slot || !appointment.data) {
        appointment.error = {
          service: !appointment.data,
          slot: !appointment.slot,
        };

        isValid = false;
      }
    });
    setAppointments([...appointments]);
    if (!isValid) {
      notification.error({
        message: "Please fill up all service information",
      });
      return isValid;
    }
    setIsContinueBooking(true);
    return isValid;
  };

  const filterSlots = (slots) => {
    if (salon.startBooking === "1 day") {
      return slots.map((item) => ({
        name: moment(item, "HH:mm").format("hh:mm A"),
        value: item,
      }));
    }
    let startTime = moment();
    if (salon.startBooking === "1 hour") {
      startTime.add(1, "hours");
    } else if (salon.startBooking === "2 hour") {
      startTime.add(2, "hours");
    }
    startTime = startTime.format("HH:mm");
    if (
      moment(appointmentDate).format("YYYY-MM-DD") >
      moment().format("YYYY-MM-DD")
    ) {
      return (slots || []).map((item) => ({
        name: moment(item, "HH:mm").format("hh:mm A"),
        value: item,
      }));
    }
    let filterSlots = (slots || []).filter((slot) => {
      if (slot > startTime) {
        return slot;
      }
    });
    return filterSlots.map((item) => ({
      name: moment(item, "HH:mm").format("hh:mm A"),
      value: item,
    }));
  };

  const validateCustomerData = () => {
    let isValid = true;
    if (!customerInfo.name || !customerInfo.mobileNumber || customerInfo.mobileNumber.length < 12) {
      isValid = false;
    }
    if (!isValid) {
      notification.error({
        message: "Please fill up customer information",
      });
      return;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateAppointments() || !validateCustomerData()) {
      return;
    }
    const updatedAppointments = appointments.reduce((acc, item) => {
      let isHandsServiceSelected = false;
      const data = item.data.map((appointment) => {
        let request = appointment.request;
        if (appointment.serviceType === "hands") {
          isHandsServiceSelected = true;
        } else {
          request = isHandsServiceSelected ? false : request;
        }
        return {
          ...appointment,
          request,
          clientName: item.clientName,
        };
      });
      acc.appointments = data;
      return [...acc, ...data];
    }, []);
    setSubmitDisable(true);
    bookAppointment({
      salonId: salon._id,
      organizationId: salon.organization,
      appointmentDate,
      appointments: updatedAppointments,
      bookingText: salon.bookingText,
      customerInformation: {
        ...customerInfo,
        mobileNumber: customerInfo.mobileNumber.replace(/\./g, ''),
      },
      bookingId: query.bookingId,
    });
  };

  const onServiceSelect = (selectedAppointments, index) => {
    if (!selectedAppointments || !selectedAppointments[currentIndex]) {
      return;
    }
    const selectedSerices = selectedAppointments[currentIndex]?.service || [];
    const obj = {};
    for (let service of selectedSerices) {
      if (service.acrylic) {
        obj.acrylic = true;
      }
      if (service.dip) {
        obj.dip = true;
      }
      if (service.wax) {
        obj.wax = true;
      }
      if (service.design) {
        obj.design = true;
      }
      if (service.serviceHardGel) {
        obj.hardGel = true;
      }
      if (service.serviceGel_x) {
        obj.gel_x = true;
      }
      if (service.servicePolyGel) {
        obj.polyGel = true;
      }
      // if (service.toggleOption === "feet") {
      //   obj.pedicure = true;
      // }
    }
    getWorkers({
      params: {
        salonId: salon._id,
        organizationId: salon.organization,
        date: appointmentDate,
        ...obj,
      },
    });
    setCurrentIndex(index);
    setAppointments([...selectedAppointments]);
  };

  const formatPhone = (phoneNumber) => {
    if(!phoneNumber) {
        return "";
    }
    var numbers = phoneNumber.replace(/\D/g, ''),
    char = {3: '.', 6: '.'};
    phoneNumber = '';
    for (var i = 0; i < numbers.length; i++) {
        phoneNumber += (char[i] || '') + numbers[i];
    }

    return phoneNumber;
}

  const onMobileNumberChange = (e) => {
    const formattedNumber = formatPhone(e.target.value);
    
    setCustomerInfo({
      ...customerInfo,
      mobileNumber: formattedNumber,
    });
  }

  if (bookAppointmentLoading) {
    return <Loader />;
  }

  return (
    <>
      <RemainingTimer startTimer={startTimer} resetTimer={resetTimer} />
      <div className="main-wrapper">
        <Salon onSalonDataReceived={onSalonDataReceived} />
        <div className="main appointment">
          <div className="container">
            <div className="service-container">
              <div className="appointment__form">
                <div className="appointment__form__date text--center service">
                  <AppointmentDate
                    resetData={resetData}
                    getServices={getServices}
                    salon={salon}
                    setAppointmentDate={setAppointmentDate}
                    appointmentDate={appointmentDate}
                  />
                </div>
                <div className="service">
                  {appointments.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="service__row flex flex--row flex--ai-center">
                          <hr
                            style={{
                              width: "100%",
                              "maxWidth": "800px",
                              "marginTop": "-20px",
                              "marginBottom": "30px",
                              "borderTop": "1px solid #05b6d8",
                            }}
                          />
                          <div className="flex__col">
                            <div className="flex flex--row">
                              <Service
                                appointments={appointments}
                                index={index}
                                setAppointments={setAppointments}
                                onServiceSelect={onServiceSelect}
                                servicesLoading={servicesLoading}
                                setCurrentIndex={setCurrentIndex}
                                resetBookingApts={resetBookingApts}
                                resetTimer={handleResetTimer}
                                salon={salon}
                              />
                              <Worker
                                appointments={appointments}
                                index={index}
                                setAppointments={setAppointments}
                                setCurrentIndex={setCurrentIndex}
                                workerLoading={workerLoading}
                                filterSlots={filterSlots}
                                getSlots={getSlots}
                                resetBookingApts={resetBookingApts}
                                resetTimer={handleResetTimer}
                                salon={salon}
                              />
                              <TimeSlot
                                appointments={appointments}
                                availableSlotsLoading={availableSlotsLoading}
                                setAppointments={setAppointments}
                                index={index}
                                setCurrentIndex={setCurrentIndex}
                                salon={salon}
                                startTime={() => {if(!startTimer) setStartTimer(true)}}
                                resetTimer={handleResetTimer}
                                appointmentDate={appointmentDate}
                                resetBookingApts={resetBookingApts}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="text--center">
                    <Button
                      className="button button--link"
                      onClick={() => {
                        setCurrentIndex(appointments.length);
                        setAppointments([...appointments, {}]);
                        getServices({}, `/${salon?._id}`);
                      }}
                      style={{
                        "textTransform": "uppercase",
                        height: "100%",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <span style={{ fontSize: 30 }}>
                        + {numberToOrdinal(appointments.length + 1)} person
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="text--center">
                <Button
                  className="button button--bg"
                  onClick={validateAppointments}
                >
                  Continue Booking
                </Button>
              </div>
            </div>
            {isContinueBooking && (
              <div className="summary-container">
                <div id="summary" className="flex flex--row flex--jc-sbetween">
                  <Summary
                    appointments={appointments}
                    appointmentDate={appointmentDate}
                  />
                  <div className="flex__col--12 flex__col--full">
                    <div className="appointment__form">
                      <div className="form__group">
                        <TextInputComponent
                          id="customerName"
                          type="text"
                          placeholder="FIRST NAME"
                          value={customerInfo.name}
                          autoFocus={true}
                          onChange={(value) => {
                            setCustomerInfo({
                              ...customerInfo,
                              name: value.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form__group">
                        <TextInputComponent
                          type="text"
                          placeholder="ENTER MOBILE"
                          maxValueLength={12}
                          value={customerInfo.mobileNumber}
                          onChange={onMobileNumberChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isContinueBooking && (
              <div class="text--center">
                <Button
                  class="button button--bg button--bg--success submit-button"
                  onClick={handleSubmit}
                  disabled={submitDisable}
                >
                  Submit
                </Button>
                <div class="user-acceptance">
                  <p>
                    By clicking "BOOK APPOINTMENT", I agree to the Privacy Policy
                    and Terms of Use.
                  </p>
                  <p>
                    This will serve as my electronic signature to allow
                    salondragon to use my information.
                  </p>
                  <p>
                    I authorize salondragon to send SMS, Email notificaiton or
                    pre-recorded messages to the information provided.
                  </p>
                </div>
              </div>
            )}
          </div>
          {showCustomNotification && (
            <div
              className={classNames(
                "custom-notification",
                !salon.bookingText && "green-popup"
              )}
            >
              <div className="message">
                {!salon.bookingText ? (
                  <div>
                    <p>DONE</p>
                    <p>YOU ARE BOOKED</p>
                    <p>{moment(appointmentDate).format("dddd Do")}</p>
                    <p>
                      {moment(
                        bookAppointmentResponse?.validAppointments?.[0]?.appointment?.timing?.from,
                        "HH:mm"
                      ).format("h:mm a")}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>NOT CONFIRMED</p>
                    <p>We will review this request</p>
                    <p>and TEXT you</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Booking;
