import React from "react";
import { Select, Spin } from "antd";
import PropTypes from "prop-types";
import "./style.css";
import classNames from "classnames";

function SelectBox(props) {
  const {
    isLoading,
    options,
    isWorkerSelectBox,
    value,
    onChange,
    placeholder,
    loading,
    className,
    error,
    notFoundContent,
  } = props;
  return (
    <div
      className={classNames(
        "custom-select-box",
        className,
        error ? "error" : ""
      )}
    >
      <div className="flex__col--12 slots-title">{placeholder}</div>
      {loading ? (
        <div className="flex__col--12 text--center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="appointmentSlots">
          <div className="appointmentSlotsContainer">
          {options && options.length ? (
            options.map((item, index) => {
              return (
                <div
                  className={classNames(
                    "appointmentSlot",
                    item.value === value && "active",
                    isWorkerSelectBox && item.value === "any" && index === 0
                      ? "any-worker"
                      : "",
                      isWorkerSelectBox && item.value === "request" ? "request-worker": "",
                  )}
                  onClick={() => onChange(item.value)}
                >
                  {item.name}
                </div>
              );
            })
          ) : (
            <div className="flex__col--12 text--center appointmentSlot">
              {notFoundContent}
            </div>
          )}
          </div>
        </div>
      )}
      {/* <Select
        placeholder={placeholder}
        onChange={onChange || selectedOption}
        allowClear={allowClear}
        value={value}
        loading={loading}
        disabled={disabled}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        mode={mode}
        defaultValue={defaultValue}
        filterOption={filterOption}
        optionFilterProp="children"
        // getPopupContainer={(trigger) => trigger?.parentNode}
      >
              value={item.value}
              item={item}
              label={item.label}
              title={item.title || item.name}
              data-testid={item.value}
            >
              {item.name}
            </Select.Option>
          ))}
      </Select> */}
    </div>
  );
}

SelectBox.defaultProps = {
  options: [],
  name: "",
  disabled: false,
  placeholder: "Select an option",
  allowClear: true,
  value: undefined,
  defaultValue: undefined,
  selectedOption: null,
  onChange: () => {
    /* This is intentional */
  },
  loading: false,
    showSearch: false,
  filterOption: undefined,
  dropdownMatchSelectWidth: false,
  error: false,
  notFoundContent: "No results found",
  isWorkerSelectBox: false,
  isLoading: false,
};

SelectBox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isWorkerSelectBox: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.instanceOf(Array),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  notFoundContent: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default React.memo(SelectBox);
