import React from "react";
import moment from "moment";
import Calendar from "../../../../icons/Calendar";

const isRequestedWorker = (appointment, index) => {
  const workers = (appointment.workers || []).filter((item) => {
    return item.value === 'request'
  })
  return workers.length > 0 
}

const Summary = ({ appointments, appointmentDate }) => {
  return (
    <div class="flex__col--12  flex__col--full">
      <div class="appointment__summary">
        <div class="appointment__summary__header">
          <h5 class="appointment__summary__header__title">SUMMARY</h5>
        </div>
        <div class="appointment__summary__body summary">
          <div class="summary__date">
            <Calendar /> {moment(appointmentDate).format("dddd Do")}
          </div>
          <div class="summary__date">
            {moment(appointments[0]?.slot, "HH:mm").format("hh:mm A")}
          </div>
          <div class="summary__serivices">
            {appointments.map((appointment) => {
              const services = appointment.service;
              return services?.map((item) => {
                return (
                  <div class="summary__services__item">
                    <div class="summary__services__item__service">
                      {item?.name} {!isRequestedWorker(appointment) && item?.worker?.name}
                    </div>
                  </div>
                );
              })
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
