import { findIndex, isEmpty } from 'lodash';
import moment from 'moment';

const updateWorker = (appointments) => {
  let worker = {};
  for (let i = 0; i < appointments.length; i++) {
    if (appointments[i].worker) {
      worker = appointments[i].worker;
      break
    }
  }
  for (let i = 0; i < appointments.length; i++) {
    if (!appointments[i].worker) {
      if (Object.keys(worker).length) {
        appointments[i].worker = worker;
      } else {
        appointments[i].worker = appointments[i].workersResponse[0];
      }
    }
  }
  return appointments;
}

export const setWorker = (element) => {
    if (isEmpty(element.worker) || element?.worker?.value === "any") {
      for (const key in element.totalSlots) {
        if (key !== "any") {
          for (let j = 0; j < element.totalSlots[key].length; j++) {
            if (element.totalSlots[key][j] === element.slot) {
                const index = findIndex(element.workersResponse, (item) => {
                    return item.value === key;
                })
                if (index > -1) {
                    element.worker = element.workersResponse[index];
                } else {
                    element.worker = {
                      name: key,
                      value: key,
                    };
                }
                break;
            }
          }
        }
      }
    }
    let startTime = element.slot;
    for (let j = 0; j < element?.service?.length; j++) {
      if (element?.service?.length === 1) {
        element.service[j].worker = element.worker;
      } else if (
        element.service[j].toggleOption === "hands" ||
        element.service[j].toggleOption === "special"
      ) {
        element.service[j].worker = element.worker;
      } else {
        let ary = element.totalSlots.slotsObj[element.slot];
        if (ary && ary.length) {
          const index = findIndex(ary, (item) => {
            return item.workerId === element.worker.value && item.startTime;
          });
          if (index > -1) {
            element.service[j].worker = element.worker;
          } else {
            for (let k = 0; k < ary.length; k++) {
              if (ary[k].startTime === startTime) {
                const index = findIndex(element.workersResponse, (item) => {
                    return item.value === ary[k].workerId;
                })
                if (index > -1) {
                    element.service[j].worker = element.workersResponse[index];    
                } else {
                    element.service[j].worker = { value: ary[k].workerId };
                }
              }
            }
          }
        } 
      }
      element.service[j].startTime = startTime;
      startTime = moment(startTime, "HH:mm")
        .add(element.service[j].time, "minutes")
        .format("HH:mm");
      element.service[j].endTime = startTime;
    }
    return element;
}

function findMaxRepeatedIndices(arrayOfArrays) {
  let maxCount = 0;
  let bestSlotIndex = 0;
  // Count occurrences of each value and track indices
  arrayOfArrays?.forEach((innerArray, index) => {
    let count = {};
    innerArray.forEach((item) => {
      count[item.workerId] = (count[item.workerId] || 0) + 1;
    });
    for (const value in count) {
      if (count[value] > maxCount) {
        maxCount = count[value];
        bestSlotIndex = index;
      }
    }
    count = {};
  });

  return bestSlotIndex;
}

export const calculateSlots = (element, value) => {
  const { totalSlots } = element;
  const consecutiveSlots = totalSlots[value];
  const index = findMaxRepeatedIndices(consecutiveSlots);
  if (index > -1) {
    return consecutiveSlots[index] ? JSON.parse(JSON.stringify(consecutiveSlots[index])) : [];
  }
  return consecutiveSlots[0] ? JSON.parse(JSON.stringify(consecutiveSlots[0])) : [];
}

export const calculateAppointments = (appointments) => {
  for (let i = 0; i < appointments.length; i++) {
    appointments[i] = setWorker(appointments[i]);
  }
  return {
    appointments,
  }
};

export const sortTime = (time1, time2) => {
  const timeA = new Date(`1970/01/01 ${time1}`);
  const timeB = new Date(`1970/01/01 ${time2}`);

  // Compare the time objects
  return timeA.getTime() - timeB.getTime();
}

export const compareTime = (slot1, slot2) => {
  return sortTime(slot1.startTime, slot2.startTime);
}

export const parseAvailableSlots = (slots) => {
  const obj = {};
  for (let consecutiveSlots of slots) {
    consecutiveSlots = consecutiveSlots.sort(compareTime);
    if (!obj[consecutiveSlots[0].startTime]) {
      obj[consecutiveSlots[0].startTime] = []
    }
    obj[consecutiveSlots[0].startTime] = [...obj[consecutiveSlots[0].startTime], consecutiveSlots];
  }
  return obj;
};

const isValidSlot = (slot, finalSlots) => {
  if (slot?.endsWith("30") || slot?.endsWith("10") || slot?.endsWith("00")) {
    return true;
  }
  const prevSlot = moment(slot, 'HH:mm').subtract(10, 'minutes').format('HH:mm');
  const prevPrevSlot = moment(prevSlot, 'HH:mm').subtract(10, 'minutes').format('HH:mm');
  if (slot?.endsWith("20") || slot?.endsWith("40")) {
    if (finalSlots.indexOf(prevSlot) > -1 || prevPrevSlot > -1) {
      return false;
    } 
  } else if (slot?.endsWith("40")) {
    if (finalSlots.indexOf(prevSlot) > -1) {
      return false;
    } 
  } 
  return true;
}

export const filterTimes = (array) => {
  const filteredArray = [];

  for (let i = 0; i < array.length; i++) {
    isValidSlot(array[i], filteredArray) && filteredArray.push(array[i]);
  }

  return filteredArray;
}