import React from 'react';

// create functional component
const LocationIcon = () => {
  return (
    <svg viewBox="0 0 20.5 24.25" className="header__business__icon">
      <title>Asset 1</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <path
            d="M10.25,24.25A1,1,0,0,1,9.6,24c-1-.84-9.6-8.34-9.6-13.76a10.25,10.25,0,0,1,20.5,0c0,5.42-8.62,12.92-9.6,13.76a1,1,0,0,1-.65.24ZM10.25,2A8.26,8.26,0,0,0,2,10.25c0,3.51,5.46,9.15,8.25,11.67C13,19.4,18.5,13.76,18.5,10.25A8.26,8.26,0,0,0,10.25,2Z"
            fill="#4f453c"
          ></path>
          <path
            d="M10.25,15.25a5,5,0,1,1,5-5,5,5,0,0,1-5,5Zm0-8a3,3,0,1,0,3,3,3,3,0,0,0-3-3Z"
            fill="#4f453c"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;