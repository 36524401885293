import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = React.forwardRef(({
  children, type, className, disabled, ...props
}, ref) => (
  // eslint-disable-next-line react/button-has-type
  // <div style={{"padding": 5}}>
  <button type={type} className={classNames('btn', className)} disabled={disabled} ref={ref} {...props}>
    {children}
  </button>
  // </div>
));

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  className: '',
  children: [],
};

export default React.memo(Button);
