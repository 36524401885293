import React, { useState } from "react";
import Salon from "../../components/Salon";

const SocialFeedback = () => {
  const [salon, setSalon] = useState({});
  const onSalonDataReceived = (data) => {
    setSalon(data);
  };
  return (
    <>
      <Salon onSalonDataReceived={onSalonDataReceived} />
      <main className="main appointment">
        <div className="container">
          <div name="service-container">
            <div className="appointment__form">
              <div className="appointment__form__services">
                <div className="review-note-container">
                  <div className="review-note">
                    <p
                      className="font-weight-bold"
                      style={{ marginBottom: "15px" }}
                    >
                      Please write your review to know us better
                    </p>
                    <div className="review--external">
                      <div className="review-flex">
                        {salon?.feedback?.google && (
                          <div className="review-option">
                            <div className="col-md-12">
                              <img
                                className="review-image"
                                src="/google.png"
                                alt="Google Review"
                              />
                            </div>
                            <div className="text--center">
                              <a href={salon?.feedback?.google}>
                                <button
                                  className="button button--bg review-btn-lg"
                                  id="google-review"
                                  data-google-pid
                                  data-lid
                                >
                                  Review us using Google
                                </button>
                              </a>
                              <h6 className="review-option__note">
                                * Must have a Google account in order to leave a
                                review
                              </h6>
                            </div>
                          </div>
                        )}
                        <div className="review-option">
                          <div className="col-lg-12">
                            <img
                              className="review-image yelp"
                              src="/yelp.png"
                              alt="Yelp Review"
                            />
                          </div>
                          <div className="text--center">
                            <a href={salon?.feedback?.yelp}>
                              <button
                                className="button button--bg"
                                id="yelp-review"
                                data-lid
                                data-yelp-yid
                              >
                                Review us using Yelp
                              </button>
                            </a>
                            <h6 className="review-option__note">
                              * Must have an active Yelp account in order to
                              leave a review
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SocialFeedback;
