import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button";
import MultiSelectBox from "../../../../components/MultiSelectBox";
import TextInputComponent from "../../../../components/TextInput";
import { numberToOrdinal } from "../../../../lib/util";

const Service = ({
  appointments,
  index,
  setAppointments,
  onServiceSelect,
  servicesLoading,
  setCurrentIndex,
  resetBookingApts,
  salon,
  resetTimer,
}) => {
  const [clientName, setClientName] = useState('');
  const [placeholder, setPlaceHolder] = useState(`Enter Name #${index + 1}`);

  useEffect(() => {
    if (appointments?.[index]?.clientName) {
      setClientName(appointments?.[index]?.clientName);
    }
  }, [appointments]);

  const onChangeClientName = (e, index) => {
    setClientName(appointments?.[index]?.clientName);
    appointments[index].clientName = e.target.value;
    setAppointments([...appointments]);
  };

  const onServiceChange = (value, index) => {
    setCurrentIndex(index);
    const ids = appointments[index]?.data?.map((item) => {
      return item?.appointment?._id
    })
    if (ids?.length) {
      resetBookingApts({ ids, salonId: salon._id });
      resetTimer();
    }
    const serviceIndex = (appointments[index].service || []).findIndex(
      (item) => {
        return item.value === value.value;
      }
    );

    const typeServiceIndex = (appointments[index].service || []).findIndex(
      (item) => {
        return value.toggleOption === item.toggleOption;
      }
    );
    if (typeServiceIndex > -1) {
      appointments[index].service.splice(typeServiceIndex, 1);
    }

    if (serviceIndex > -1) {
      appointments[index].service.splice(serviceIndex, 1);
    } else {
      appointments[index].service = [
        ...(appointments[index].service || []),
        ...(appointments[index].services || []).filter(
          (item) => item.value === value.value
        ),
      ];
    }
    onServiceSelect(appointments, index);
    appointments[index].service = appointments[index]?.service?.sort((a, b) => {
      return b.orderValue - a.orderValue;
    });
    appointments[index].data = [];
    appointments[index].slot = null;
    appointments[index].to = null;
    appointments[index].worker = null;
    appointments[index].request = false;
    setAppointments([...appointments]);
  };

  return (
    <div className="flex__col--12">
      {appointments.length > 1 && (
        <div className="flex__col--12 text--center">
          <Button
            class="button button--bg--error submit-button"
            onClick={() => {
              appointments.splice(index, 1);
              setAppointments([...appointments]);
            }}
          >
            Delete {numberToOrdinal(index + 1)} Person
          </Button>
        </div>
      )}
      <TextInputComponent
        placeholder={placeholder}
        value={clientName}
        onChange={(e) => onChangeClientName(e, index)}
      />
      <MultiSelectBox
        options={appointments[index].services || []}
        value={appointments[index]?.service}
        onChange={(value) => onServiceChange(value, index)}
        loading={servicesLoading}
        error={appointments[index]?.error?.service}
        selectProps={{
          autoFocus: true,
        }}
        notFoundContent="NO SERVICE AVAILABLE"
      />
    </div>
  );
};

export default Service;
