import React, { useEffect, useState } from "react";
import Header from "./header";
import AppointmentInfo from "./appointmentInfo";
import "./style.css";
import Button from "../../components/Button";
import { apiUrls } from "../../api/constants";
import { useGet, usePost } from "../../hooks/use-http";
import useRedirect from "../../hooks/useRedirect";
import moment from "moment";
import { Loader } from "../../components/Loader";

const ConfirmBooking = () => {
  const { query, generatePath, push } = useRedirect();
  const [isReschedule, setReschedule] = useState(null);

  const {
    data: bookAppointmentResponse,
    bookAppointmentError,
    loading: bookAppointmentLoading,
    execute: getApptDetail,
  } = useGet(`${apiUrls.GET_BOOKING_DETAIL}/${query.id}`);

  const {
    data: bookingAction,
    bookingActionError,
    loading: bookingActionLoading,
    execute,
  } = usePost(`${apiUrls.CONFIRM_BOOKING}/${query.id}`);

  const salon = bookAppointmentResponse?.salon;

  const appointments = bookAppointmentResponse?.appointments[0];
  useEffect(() => {
    if (bookingAction) {
      if (isReschedule) {
        push(
          generatePath(
            `/:bookingId??bookingId=${appointments?.meta?.bookingId}`,
            { bookingId: salon.bookingId }
          )
        );
      }
      getApptDetail();
    }
  }, [bookingAction, isReschedule]);

  useEffect(() => {
    document.title = "confirm | cancel";
  }, []);

  const appointmentDate = moment(appointments?.appointmentDate);
  var formattedDate = appointmentDate && appointmentDate.format("dddd, MMMM D");

  const handleConfirmClick = (action) => {
    execute({ action, appointments: bookAppointmentResponse?.appointments });
    if (action === "RESCHEDULED") {
      setReschedule(true);
      return;
    }
    setReschedule(false);
  };

  if (bookAppointmentLoading || bookingActionLoading) {
    return <Loader />;
  }
  // if (["CONFIRMED", "RESCHEDULED", "CANCELED"].includes(appointments?.bookingStatus)) {
  //     return (
  //         <div class="message-overlay">
  //             <div class="message-box">
  //                 <h2>Appointments already {appointments?.bookingStatus}</h2>
  //             </div>
  //         </div>
  //     )
  // }

  const statusClass = (() => {
    let className = "appointment__status";
    if (appointments?.bookingStatus === "CONFIRMED") {
      return className + " appointment-confirmed";
    }
    if (appointments?.bookingStatus === "CANCELED") {
      return className + " appointment-cancelled";
    }

    return className + " appointment-reschedule";
  })();

  const salonName = salon?.salonName || "";
  const salonAddress = salon?.address + " " + salon?.address2;
  const salonPhone = salon?.phone;
  const filteredAppts = (bookAppointmentResponse?.appointments || []).filter(
    (item) => {
      return ["working", "paid", "finsihed"].includes(item.status);
    }
  );

  if (!bookAppointmentResponse?.appointments?.length) {
    return null
  }

  console.log("🚀 ~ file: index.jsx:154 ~ ConfirmBooking ~ filteredAppts:", filteredAppts)


  return (
    <div scroll-top="" className="main-container custom-body-bg-color">
      <ui-view autoscroll="false" className="max-height">
        <div
          data-id="complete-screen"
          className="complete-screen flex custom-body-bg-color"
        >
          <Header />
          <div
            data-id="content"
            className="complete-content content custom-body-bg-color"
          >
            <div
              data-id="appointment-details-provider"
              className="complete-content__details--provider"
            >
              {salonName}
            </div>
            <div
              data-id="appointment-details-address"
              className="complete-content__details--address"
            >
              <a href={`tel:${salonPhone}`} target="_blank">
                {salonPhone}
              </a>
            </div>
            <div
              data-id="appointment-details-address"
              className="complete-content__details--address"
            >
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${salonAddress}`}
                target="_blank"
              >
                {salonAddress}
              </a>
            </div>
            <div className="complete-content-inner">
              <span
                data-id="appointment-date"
                className="complete-content__date"
              >
                {formattedDate}
              </span>
              {["CONFIRMED", "RESCHEDULED", "CANCELED"].includes(
                appointments?.bookingStatus
              ) && (
                <span data-id="appointment-date" className={statusClass}>
                  {appointments?.bookingStatus}
                </span>
              )}
              <AppointmentInfo
                bookAppointmentResponse={bookAppointmentResponse}
              />
            </div>
          </div>
          {!filteredAppts.length && (
            <div className="complete-control content">
              <div className="complete-control__buttons">
                {!["CONFIRMED"].includes(appointments?.bookingStatus) && (
                  <Button
                    data-id="confirm-button"
                    className="button-standard button-green custom-primary-action-button"
                    onClick={() => handleConfirmClick("CONFIRMED")}
                  >
                    <span className="button-inner-text">CONFIRM</span>
                  </Button>
                )}
                {!["RESCHEDULED"].includes(appointments?.bookingStatus) && (
                  <Button
                    data-id="confirm-button"
                    className="button-standard button-yellow custom-primary-action-button"
                    onClick={() => handleConfirmClick("RESCHEDULED")}
                  >
                    <span className="button-inner-text">RESCHEDULE</span>
                  </Button>
                )}
                {!["CANCELED"].includes(appointments?.bookingStatus) && (
                  <Button
                    data-id="cancel-button"
                    className="button-standard button-red custom-secondary-action-button"
                    onClick={() => handleConfirmClick("CANCELED")}
                  >
                    <span className="button-inner-text">CANCEL</span>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        <modal-dialog
          dialogclass="md"
          data-id="decline-confirmation-dialog"
          className="modal"
        ></modal-dialog>
      </ui-view>

      {/* <div style={{ display: "none" }}>
                <TranslationButton />
            </div> */}
    </div>
  );
};

export default ConfirmBooking;
