import React, { useState, useEffect } from "react";
import moment from 'moment';
import "./style.css";

const RemainingTimer = ({ startTimer, resetTimer }) => {
  const [startTime, setStartTime] = useState(null);
  const [secondsLeft, setSecondsLeft] = useState(300);
  
  useEffect(() => {
    if (resetTimer) {
      setStartTime(moment().add(5, 'minutes'));
      setSecondsLeft(300);
    }
  }, [resetTimer])

  // Update the last update time every second
  useEffect(() => {
    if (!startTime) return;
    const updateInterval = setInterval(() => {
        const currentTime = moment();
        const remainingSeconds = startTime.diff(currentTime, 'seconds');
    
        // Update the UI with the new remaining time
        setSecondsLeft(remainingSeconds);

        if (remainingSeconds <= 0) {
            window.location.reload();
        }
    }, 1000);

    // Cleanup
    return () => {
      clearInterval(updateInterval);
    };
  }, [startTime]);

  useEffect(() => {
    if (startTimer) {
      setStartTime(moment().add(5, 'minutes'));
    } 
  }, [startTimer])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
   (startTime && <div className="timer-wrapper">
      <h3>HOLDING APPT {formatTime(secondsLeft)}</h3>
    </div>)
  );
};

export default RemainingTimer;
