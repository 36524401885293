import axios from 'axios';
import moment from 'moment';

import { apiUrls, baseUrl } from './constants';

/**
 * Server address (for api)
 * @private
 * @constant
 */

const PROTOCOL = process.env.SSL ? 'https' : 'http';
const PATH = process.env.API_PATH ? `/${process.env.API_PATH}` : '';
const API = process.env.API ? `${PROTOCOL}://${process.env.API}${PATH}/` : baseUrl;

const API_PATH = API || (PATH ? `${PATH}/` : '');

const server = axios.create({
  baseURL: API_PATH,
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const multipartServer = axios.create({
  baseURL: API_PATH,
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

server.interceptors.response.use((response) => response.data, (error) => Promise.reject(error));

server.interceptors.request.use((request) => {
  if (request?.url !== apiUrls.REFRESH_TOKEN) {
    localStorage.setItem('lastActivity', moment().toISOString());
  }
  return request;
}, (error) => Promise.reject(error));

export default server;
