import React from "react";
import SelectBox from "../../../../components/SelectBox";

export default Worker = ({
  appointments,
  index,
  setCurrentIndex,
  setAppointments,
  workerLoading,
  filterSlots,
  getSlots,
  resetBookingApts,
  resetTimer,
  salon
}) => {
  const handleChange = (value) => {
    setCurrentIndex(index);
    const ids = appointments[index]?.data?.map((item) => {
      return item?.appointment?._id
    })
    if (ids?.length) {
      resetBookingApts({ ids, salonId: salon._id });
      resetTimer();
    }
    if (value === "any") {
      appointments[index] = {
        ...appointments[index],
        request: false,
        slot: null,
        worker: null,
        data: [],
        to: null,
        workers: [
          { name: "Any Technician", value: "any" },
          { name: "Request Technician", value: "request" },
        ],
      };

    } else if (value === appointments[index]?.worker?.value) {
      appointments[index] = {
        ...appointments[index],
        slot: null,
        to: null,
        worker: null,
        request: false,
        data: [],
      };

    } else if (value === "request") {
      appointments[index] = {
        ...appointments[index],
        data: [],
        slot: null,
        to: null,
        workers: [
          { name: "Any Technician", value: "any" },
          ...appointments[index].workersResponse,
          { name: "Any", value: "any" },
        ],
      };

    } else {
      const selectedWorker = (appointments[index].workers || []).find(item => item.value === value);
      appointments[index] = {
        ...appointments[index],
        worker: selectedWorker || {},
        request: !!selectedWorker,
        slot: null,
        to: null,
        slots: filterSlots(appointments[index]?.totalSlots?.[appointments[index]?.worker?.value] || []),
      };
    }
    getSlots();

    setAppointments([...appointments]);
  };

  return (
    <div className="flex__col--12">
      <SelectBox
        placeholder="Select Technician"
        options={appointments[index].workers || []}
        defaultValue={appointments[index]?.workers?.[0] || "any"}
        isWorkerSelectBox
        onChange={handleChange}
        value={appointments[index]?.worker?.value || "any"}
        loading={workerLoading}
        error={appointments[index]?.error?.worker}
        notFoundContent="NO TECHNICIAN AVAILABLE"
      />
    </div>
  );
};
