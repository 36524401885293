import React from "react";

export default () => {
  return (
    <svg viewBox="0 0 22.53 20">
      <title>Asset 5</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_3" data-name="Layer 3">
          <path
            className="cls-1"
            d="M21.53,20H1a1,1,0,0,1-1-1V2.67a1,1,0,0,1,1-1H21.53a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1ZM2,18H20.53V3.67H2V18Z"
          ></path>
          <path
            className="cls-1"
            d="M17.7,5.33a1,1,0,0,1-1-1V1a1,1,0,0,1,2,0V4.33a1,1,0,0,1-1,1Z"
          ></path>
          <path
            className="cls-1"
            d="M5,5.33a1,1,0,0,1-1-1V1A1,1,0,0,1,6,1V4.33a1,1,0,0,1-1,1Z"
          ></path>
          <path
            className="cls-1"
            d="M21.47,8.57H1.14a.5.5,0,0,1,0-1H21.47a.5.5,0,0,1,0,1Z"
          ></path>
          <polygon
            className="cls-1"
            points="5.68 10.6 3.74 10.6 3.74 12.54 5.68 12.54 5.68 10.6 5.68 10.6"
          ></polygon>
          <polygon
            className="cls-1"
            points="9.04 10.6 7.1 10.6 7.1 12.54 9.04 12.54 9.04 10.6 9.04 10.6"
          ></polygon>
          <polygon
            className="cls-1"
            points="12.39 10.6 10.45 10.6 10.45 12.54 12.39 12.54 12.39 10.6 12.39 10.6"
          ></polygon>
          <polygon
            className="cls-1"
            points="15.75 10.6 13.81 10.6 13.81 12.54 15.75 12.54 15.75 10.6 15.75 10.6"
          ></polygon>
          <polygon
            className="cls-1"
            points="19.11 10.6 17.17 10.6 17.17 12.54 19.11 12.54 19.11 10.6 19.11 10.6"
          ></polygon>
          <polygon
            className="cls-1"
            points="5.68 13.72 3.74 13.72 3.74 15.65 5.68 15.65 5.68 13.72 5.68 13.72"
          ></polygon>
          <polygon
            className="cls-1"
            points="9.04 13.72 7.1 13.72 7.1 15.65 9.04 15.65 9.04 13.72 9.04 13.72"
          ></polygon>
          <polygon
            className="cls-1"
            points="12.39 13.72 10.45 13.72 10.45 15.65 12.39 15.65 12.39 13.72 12.39 13.72"
          ></polygon>
          <polygon
            className="cls-1"
            points="15.75 13.72 13.81 13.72 13.81 15.65 15.75 15.65 15.75 13.72 15.75 13.72"
          ></polygon>
          <polygon
            className="cls-1"
            points="19.11 13.72 17.17 13.72 17.17 15.65 19.11 15.65 19.11 13.72 19.11 13.72"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
