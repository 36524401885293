import React from 'react'
import Salon from '../../components/Salon';
import useRedirect from '../../hooks/useRedirect'

const Feedback = () => {
  const { params, generatePath, push } = useRedirect()
  const handleNext = () => {
    push(generatePath('/:salonId/review/:clientId/social', params))
  }
  const onSalonDataReceived = (data) => {
    if (data) {
      
    }
  }
    return (
      <>
        <Salon onSalonDataReceived={onSalonDataReceived} />
        <main className="main appointment">
        <div className="container">
          <div name="service-container">
            <div className="appointment__form">
              <div className="appointment__form__services">
                <h4 className="review-title text--center">Rate your experience</h4>
                <div className="service ">
                  <form>
                    <div className="react-stars-wrapper-0284967011310876" style={{display: 'flex'}}>
                      <div tabIndex={0} aria-label="add rating by typing an integer from 0 to 5 or pressing arrow keys" className="undefined react-stars" style={{overflow: 'hidden', position: 'relative', height:'100px'}}>
                        <span className data-index={0} data-forhalf="★" style={{position: 'relative', overflow: 'hidden', cursor: 'pointer', display: 'block', float: 'left', color: 'rgb(255, 215, 0)', fontSize: '50px'}}>★</span><span className data-index={1} data-forhalf="★" style={{position: 'relative', overflow: 'hidden', cursor: 'pointer', display: 'block', float: 'left', color: 'rgb(255, 215, 0)', fontSize: '50px'}}>★</span><span className data-index={2} data-forhalf="★" style={{position: 'relative', overflow: 'hidden', cursor: 'pointer', display: 'block', float: 'left', color: 'rgb(255, 215, 0)', fontSize: '50px'}}>★</span><span className data-index={3} data-forhalf="★" style={{position: 'relative', overflow: 'hidden', cursor: 'pointer', display: 'block', float: 'left', color: 'rgb(255, 215, 0)', fontSize: '50px'}}>★</span><span className data-index={4} data-forhalf="★" style={{position: 'relative', overflow: 'hidden', cursor: 'pointer', display: 'block', float: 'left', color: 'rgb(255, 215, 0)', fontSize: '50px'}}>★</span>
                        <p role="status" style={{position: 'absolute', left: '-200rem'}}>5</p>
                      </div>
                    </div>
                    <div className="text--center">
                      <button className="button button--bg" onClick={handleNext}>Next</button>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      </>
    );
}

export default Feedback;