import { useEffect, useState } from "react";
import api from "../api";

const useGet = (path, payload, config) => {
  const newConfig = Object.assign({ auto: true }, config);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function execute(extras, extraUrl) {
    setLoading(true);
    await api
      .get({
        url: extraUrl ? path + extraUrl : path,
        params: extras || payload,
      })
      .then((value) => {
        setData(value);
        setError("");
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }
  useEffect(() => {
    if (newConfig && newConfig.auto) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    data: data,
    execute,
    error,
  };
};

const usePost = (path, payload, config) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function execute(extras) {
    setLoading(true);
    await api
      .post({
        url: path,
        data: extras, 
      })
      .then((value) => {
        setData(value);
        setError("");
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }
  useEffect(() => {
    if (config && config.auto) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    data: data,
    execute,
    error,
  };
};

const usePut = (path, payload, config) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function execute(params) {
    setLoading(true);
    await api
      .put(path, params || payload, {
        ...config,
        headers: {},
      })
      .then((value) => {
        setData(value);
        setError("");
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }
  useEffect(() => {
    if (config && config.auto) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    data: data,
    execute,
    error,
  };
};

export { useGet, usePost, usePut };
