import React, {useEffect} from "react";
import { notification } from "antd";
import SelectBox from "../../../../components/SelectBox";
import { calculateSlots } from "../../helpers";
import { apiUrls } from "../../../../api/constants";
import { usePost } from "../../../../hooks/use-http";

const TimeSlot = ({
  setCurrentIndex,
  appointments,
  setAppointments,
  index,
  availableSlotsLoading,
  salon,
  appointmentDate,
  startTime,
  resetBookingApts,
  resetTimer,
}) => {

  const {
    data: bookAppointmentResponse,
    bookAppointmentError,
    execute: bookAppointment,
  } = usePost(apiUrls.TEMP_BOOK_APPOINTMENTS);

  useEffect(() => {
    if (bookAppointmentError) {
      notification.error({
        message: "Error",
        description: "Error while making appts",
      });
    }
  }, [bookAppointmentError]);

  useEffect(() => {
    if (bookAppointmentResponse) {
      appointments?.forEach((item) => {
        const data = item?.data?.map((appointment) => {
          const validAppointmentIndex = bookAppointmentResponse?.validAppointments?.findIndex((item) => {
            return item.staffId === appointment.workerId && item?.timing?.from === appointment.startTime;
          });
          if (validAppointmentIndex > -1) {
            appointment.appointment = bookAppointmentResponse?.validAppointments[validAppointmentIndex];
          }
          return {
            ...appointment,
            request: item.request,
            clientName: "temp",
          };
        });
        item.data = data;
      });
      
      setAppointments([...appointments]);
    }
  }, [bookAppointmentResponse]);

  const createTempAppointments = (appointment = {}) => {
    let isHandsServiceSelected = false;
    const data = appointment?.data?.map((item) => {
      let request = appointment.request;
      if (item.serviceType === "hands") {
        isHandsServiceSelected = true;
      } else {
        request = isHandsServiceSelected ? false : request;
      }
      return {
        ...item,
        request: request,
        clientName: "temp",
      };
    });
    bookAppointment({
      salonId: salon._id,
      organizationId: salon.organization,
      appointmentDate,
      appointments: data,
      bookingText: salon.bookingText,
    });
  }

  const handleChange = (value) => {
    setCurrentIndex(index);
    const ids = appointments[index]?.data?.filter((item) => {
      if (item?.appointment?._id) {
        return item;
      }
    }).map((item) => item.appointment._id);

    if (ids?.length) {
      resetBookingApts({ ids, salonId: salon._id });
    }
    
    resetTimer();
    if (value === appointments[index]?.slot) {
      appointments[index].slot = null;
      appointments[index].data = null;

    } else {
      appointments[index].slot = value;
      appointments[index].data = calculateSlots(appointments[index], value);
      startTime();
      createTempAppointments(appointments[index]);
      

    }
    setAppointments([...appointments]);
  };
  return (
    <div className="flex__col--12">
      <SelectBox
        placeholder="Select time"
        options={appointments[index].slots || []}
        value={appointments[index]?.slot}
        onChange={handleChange}
        loading={availableSlotsLoading}
        error={appointments[index]?.error?.slot}
        notFoundContent="NO TIMES AVAILABLE"
      />
    </div>
  );
};

export default TimeSlot