import React, { useEffect } from "react";
import { apiUrls } from "../../api/constants";
import { useGet } from "../../hooks/use-http";
import useRedirect from "../../hooks/useRedirect";
import { LocationIcon, PhoneIcon } from "../../icons";
import "./style.css";
import { Spin } from "antd";

const Salon = ({ onSalonDataReceived }) => {
  const { params } = useRedirect();
  const { data, loading } = useGet(
    `${apiUrls.SALON_DETAIL}/${params.salonId}`
  );

  useEffect(() => {
    if (data) {
      onSalonDataReceived(data);
    }
  }, [data]);

  if (loading || !data) {
    return (
      <div className="flex__col--12 text--center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <header className="header">
      {/* <div className="header__bg"></div> */}
      <div className="header__business text--center">
        {/* <div className="header__business__logo">
          <img
            src=""
            alt={data.salonName}
            className="header__business__logo__image"
          />
        </div> */}
        <h1 className="header__business__name">
          <div>{data.salonName}</div>
        </h1>
        <p className="header__business__address">
          <LocationIcon />
          <a
            href={`http://maps.google.com/?q=${data.address + data.address2}`}
            target="_blank"
          >
            {data.address}
          </a>
        </p>
        <p className="header__business__address">
          {data.address2}
        </p>
        <div className="header__business__contact flex flex--ai-center flex--jc-center">
          <p className="header__business__contact--phone">
            <a href={`tel: ${data.salonPhone}`} target="_blank">
              <PhoneIcon />
              {data.salonPhone}
            </a>
          </p>
          {/* <p className="header__business__contact--email">
            <EmailIcon />
            <a href="mailto:goldennailsaz@gmail.com" target="_blank">
              {data.email}
            </a>
          </p> */}
        </div>
      </div>
    </header>
  );
};

export default Salon;
