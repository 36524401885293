import React from 'react';
import moment from 'moment';


const AppointmentInfo = ({ bookAppointmentResponse }) => {
    const appointments = bookAppointmentResponse?.appointments;

    return (<>
        <div style={{ paddingBottom: 30 }}>
            {(appointments || []).map((appointment) => {
                const appointmentTime = appointment && moment(appointment?.timing?.from, 'h:mm A');

                var time = appointmentTime && appointmentTime.format('h:mm');
                var amPm = appointmentTime && appointmentTime.format('A');
                return (
                    <div className="complete-content__details-wrapper">
                        <div
                            data-id="appointment-time"
                            className="complete-content__time"
                        >
                            <div>
                                <span className="complete-content__time--nums">
                                    {time}
                                </span>

                                <span className="complete-content__time--am-pm">
                                    {amPm}
                                </span>
                            </div>

                        </div>

                        <div
                            data-id="appointment-details"
                            className="complete-content__details"
                        >
                            <div
                                data-id="appointment-details-provider"
                                className="complete-content__details--provider"
                            >
                                {appointment.service.name}
                            </div>
                            <div className="booking-status">
                                <span >
                                    {appointment?.bookingStatus}
                                </span>
                            </div>
                        </div>
                    </div>
                )

            })}
        </div>
    </>)
}

export default AppointmentInfo;
